@media (min-width: 576px){
.modal-wide {
    max-width: 80%;
    margin: 1.75rem auto;
   
}
}

.modal-wide {
 font-size: 12px;  
}