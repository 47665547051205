.k-i-x.badge-button-icon {
    font-size: 10px;
    margin-left: 4px;
    margin-top: -2px;
    opacity: 0.6;
    &:hover {
        opacity: 1;
        color: #fff;
    }
}
