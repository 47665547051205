.advanced-search {
    &-box {
        flex-grow: 1;
        &-row {
            display: flex;
            justify-content: space-between;
            width: 100%;
            @include media-breakpoint-down(sm) {
                flex-flow: wrap;
                > div,
                .k-dropdownlist {
                    width: 100% !important;
                }
                .prepend-elements {
                    margin-bottom: 0.5rem;
                }
            }
        }
        .nav-pills {
            @include media-breakpoint-up(md) {
                margin-right: -285px;
                @at-root .sidebar-collapse + .body-and-footer .page-toolbar & {
                    margin-right: -285px;
                }
            }
            @include media-breakpoint-up(lg) {
                margin-right: -286px;
            }
        }
    }
    &-start {
        position: relative;
        .form-control {
            padding-right: 40px;
        }
        @include media-breakpoint-down(sm) {
            width: calc(100% - 39px);
        }
    }

    &-inner {
        display: flex;
        @include media-breakpoint-up(md) {
            margin-right: 0.5rem;
        }

        .btn {
            &-search {
                position: absolute;
                right: 0;
                top: 0;
                background: none;
                border: 0;
                opacity: 0.4;
                &:hover {
                    opacity: 1;
                    background: none;
                }
            }
            &-advanced-search {
                background-color: $white;
                border-color: $input-border-color;
                padding-top: 0;
                padding-bottom: 0;
                border-radius: 0;
                border-left: 0;
            }
        }
        i {
            line-height: 28px;
            font-size: 22px;
        }
    }
}
@include media-breakpoint-down(sm) {
    .btn-two-groups {
        .btn {
            width: 50%;
        }
        .k-button {
            &-group {
                width: 50%;
            }
            &:not(.k-icon-button) {
                width: 100%;
            }
        }
    }
}

.k-grid-pr-list {
    table tr:hover td {
            cursor: pointer !important;
        }
    max-height: calc(100vh - 216px);
   th,
    td {
        width: 150px;
    }
    @include media-breakpoint-down(xl) {
        max-height: calc(100vh - 262px);
    }
    @media (max-width: 900px) {
        max-height: calc(100vh - 310px);
    }
    @media (max-width: 645px) {
        max-height: calc(100vh - 400px);
    }
    
}
[class*="pr-status-"] {
    background-color: $gray-600;
    white-space: normal;
}
[class*="pr-status-originator"] {
    background-color: $dark;
    white-space: normal;
}
.action-type {
    &-PR {
        background-color: $primary;
    }
    &-PO {
        background-color: $info;
    }
    &-Invoice {
        background-color: $gray-600;
    }
    &-PRR {
        background-color: $blue-300;
    }
}
.pr-status {
    &-draft,
    &-originator {
        background-color: $dark;
    }

    &-approved {
        background-color: $success;
    }
    &-partially-approved {
        background-color: $warning;
    }
    &-submitted {
        background-color: $primary;
    }
    &-waiting-for {
        background-color: $info;
    }
    &-rejected {
        background-color: $danger;
    }

    &-po-updated {
        background-color: $primary;
      }
    &-canceled {
        background-color: $danger;
    }
    &-breezy-integrated {
        background-color: $green-500;
      }

}


@include media-breakpoint-down(md) {
    .k-window-content .g-3.mb-1.small.fw-bold.row {
        display: none;
    }
}

.ellipisis-none{
    text-overflow:initial !important;
}