.k-list-item-text,
.k-selected,
.k-input-value-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42857;
}
.page-toolbar {
    .page-title {
        line-height: 38px;
        margin: 0;
    }
    .k-dropdownlist {
        // margin-left: 10px;
        // margin-right: 10px;
        // min-width: 200px;
    }

    // p {
    //     white-space: nowrap;
    //     margin: 10px;
    //     text-align: center;
    //     vertical-align: center;
    //     font-size: 16px;
    // }
    .k-button-group + .k-button-group {
        margin-left: 0.5rem;
    }

    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    white-space: nowrap;
    align-items: flex-start;
}

.loading-dialog {
    height:100%;
    position:fixed;
    width:100%;
    top:0px;
    left:0px;
}
// .k-grid {
//     font-size: 12px !important;
//     line-height: 1.42857;
// }
.k-grid-reviewer-list{
    col, th, td{
        &:nth-child(3),
        &:nth-child(4){
            min-width:150px !important
        }
        &:nth-child(5), &:last-child{
            min-width: 250px !important;
        }
    }
}

.badge{
    border-radius: 6px;
}

.k-window-actions{

    margin-top:0;
    margin-bottom:0;
}