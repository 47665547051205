.navbar {
    @include media-breakpoint-down(lg) {
        position: fixed !important;
        top: 0;
        left: 0;
        width: 100%;
    }
    &.bg-light {
        background-color: #f5f5f5 !important;
    }
    font-size: 18px;
    z-index: 3;
    font-family: "Open Sans", sans-serif;
    border: 1px solid #e2e2e2;
    box-shadow: 0 0 5px inset rgba(0, 0, 0, 0.16);
    min-height: 62px;
    .nav-link {
        &:hover {
            background-color: $dropdown-link-hover-bg;
        }
    }
    .dropdown {
        &-menu {
            font-size: inherit;
            .dropdown-toggle {
                padding-left: 1rem;
                padding-right: 1.25rem;
                &:after {
                    position: absolute;
                    right: 10px;
                    top: 15px;
                }
            }
        }
    }
    &-nav-scroll {
        max-height: 80vh;
    }
}

.nav {
    &-user {
        .dropdown-toggle:before {
            content: "account_circle";
        }
    }
    &-notification {
        @include media-breakpoint-down(lg) {
            width: 50px;
            height: 59px;
        }
        .dropdown-toggle {
            &:before {
                content: "notifications";
            }
        }
    }
    &-icon {
        i {
            font-size: 16px;
            vertical-align: -2px;
        }
        .dropdown-toggle:before {
            font-family: "Material Icons";
            font-weight: normal;
            font-style: normal;
            font-size: 30px;
            line-height: 32px;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-feature-settings: "liga";
            -webkit-font-smoothing: antialiased;
        }
    }
}
@include media-breakpoint-down(lg) {
    .dropend .dropdown-menu[data-bs-popper] {
        margin-right: 0.125rem;
    }
}
.dropdown {
    &-item {
        color: $navbar-light-color;
        &:hover {
            color: $navbar-light-color;
            background-color: rgba(0, 100, 155, 0.1);
            // text-decoration: if($link-hover-decoration == underline, none, null);
            // @include gradient-bg($navbar-light-color);
        }
        &:focus {
            color: $navbar-light-color;
        }
        &.active,
        &:active {
            color: $white;
        }
    }
    &-menu {
        background-color: rgba($white, 0.9);
    }
}

.nw-settings {
    display: flex;
    align-items: center;
}

.nw_megamenu {
    > .dropdown-menu {
        padding: 0;
        width: 100%;
        &.show {
            @include media-breakpoint-up(lg) {
                width: 1000px;
            }
        }
        @include media-breakpoint-down(lg) {
            position: static;
        }
    }
    &-left {
        background-color: $primary-dark;
        width: 270px;
        padding: 10px;
        max-height: 500px;
        overflow: auto;
        .list-group-item {
            background-color: transparent;
            color: rgba($white, 0.7);
            line-height: 45px;
            border-bottom: 1px solid rgb(80, 125, 144);
            white-space: nowrap;
            &:hover {
                color: $white;
            }
            & + .list-group-item.active {
                margin-top: 0;
                border: 0 0 1px;
                border-top-width: 0;
                &:not(:last-child) {
                    border-bottom: 1px solid rgb(80, 125, 144);
                }
            }
            &.active {
                color: $white;
            }
        }
    }
    &-right {
        width: calc(100% - 220px);
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        max-height: 500px;
        overflow: auto;
        align-content: flex-start;
        &-item {
            width: 90px;
            padding: 5px;
            display: block;
            text-align: center;
            position: relative;
            text-decoration: none;
            i {
                position: absolute;
                top: 50px;
                right: 10px;
                background-color: #fff;
                border-radius: 50%;
                cursor: pointer;
                &:before {
                    content: "add_circle";
                }
                &.favourite:before {
                    content: "remove_circle";
                }
            }
            img,
            span {
                width: 100%;
            }
            img {
                height: 70px;
                width: 70px;
                object-fit: cover;
                margin-bottom: 5px;
            }
            span {
                display: block;
                font-size: 13px;
            }
        }
    }
}

.nw-switch-user {
    .k-grid {
        font-size: 12px;
    }
}

.social-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.navbar-collapse {
    > .nav-item > a {
        padding: 15px 20px;
        font-size: 20px;
        &:after {
            display: none;
        }
    }
    + .navbar-nav {
        .dropdown-toggle:after {
            display: none;
        }
        .nav-link {
            padding: 11px 10px;
            display: inline-flex;
            height: 100%;
            align-items: center;
        }
        .material-icons {
            font-size: 30px;
        }
    }
    @include media-breakpoint-down(lg) {
        > .nav-item {
            float: left;
            width: 100%;
        }
        .dropdown-menu {
            position: static;
            width: 100%;
        }
        .dropend .dropdown-menu[data-bs-popper] {
            width: calc(100% - 0.15rem);
        }
    }
}

.navbar-right li > a i.material-icons {
    font-size: 30px;
}

.alert-menu {
    position: relative;
    .notification-count {
        border-radius: 30px;
        background: $danger;
        border: 1px solid #fff;
        min-width: 22px;
        height: 22px;
        display: block;
        font-size: 11px;
        font-weight: 700;
        color: #fff;
        position: absolute;
        top: 10px;
        right: 0;
        line-height: 20px;
        text-align: center;
        padding: 0 5px;
        pointer-events: none;
    }
}

@include media-breakpoint-down(lg) {
    .navbar-collapse {
        margin: 0 -15px;
        max-height: 75vh;
        overflow: auto;
    }
    .navbar-toggler {
        position: absolute !important;
        right: 0;
        top: 6px;
    }
    .navbar-nav.navbar-right-custom {
        position: absolute;
        top: 0;
        right: 55px;
        display: inline-block;
        flex-direction: row;
        > div {
            float: left;
        }
        .dropdown-menu {
            position: absolute;
        }
    }
}
.nav-notification {
    .dropdown-menu {
        padding: 0;
        @include media-breakpoint-down(sm) {
            @at-root .navbar-nav & {
                position: fixed;
                right: 0;
                top: 60px;
                width: 100%;
            }
        }
    }
    &-body {
        max-height: calc(100vh - 200px);
        overflow: auto;
    }
}
.notification-item {
    font-size: 12px;
    min-width: 250px;
    > a {
        display: block;
    }
    i.material-icons {
        font-size: 11px !important;
    }
    &-footer.dropdown-item {
        text-align: center;
        font-size: 13px;
        background-color: $gray-300;
        padding: 10px;
    }
}

.switch-back {
    background: #dbf2ff !important;
    border-radius: 15px;
    font-size: small;
    font-weight: 500;
    margin-top: 10px;
    margin-right: 10px;
    line-height: 1;
    font-weight: 700;
    color: #004368;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0%;

    i {
        vertical-align: middle;

        .material-icons1 {
            font-family: "Material Icons";
            font-size: 18px;
            width: 18px;
            height: 18px;
        }
    }

    a {
        color: #fff;
        background: #00649b;
        padding: 3px;
        border-radius: 0 15px 15px 0;
        text-decoration: none;
        margin-left: 5px;
        position: relative;
        width: 30px;
        display: inline-block;
        height: 30px;
        i {
            .icon-test {
                font-family: "Material Icons";
                font-size: 18px;
                width: 18px;
                height: 18px;
            }
        }
    }
}
