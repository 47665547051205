.invalid-field {
    color: $danger;
    margin-top: 3px;
    font-size: small;
    font-weight: $font-weight-bold;
}
.xtupload-button {
    max-width: 125px;
    overflow: hidden;
    @at-root .custom-fileupload & {
        max-width: 117px;
    }
    + .k-grid {
        margin-top: 10px;
    }
}



    @media (max-width: 575.98px) {
       .btn-sm-full{
        width:100% !important;
       }
    }

.job-description-and-comments {
  max-height: 12.5rem;
  overflow-y: auto;
}