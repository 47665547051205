.admin-settings {
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    a {
        color: #00649b;
        text-decoration: none;
        background-color: transparent;
    }

    .card {
        // margin: 20px;
        border-radius: 5px;
        box-shadow: 0 rgba(0, 0, 0, 0.16);
        display: flex;
        align-items: center;
        transition: 0.2s ease-in-out;
        background: #fff;
        overflow: hidden;
        color: #495057;
        padding: 20px;
        height: auto;

        div {
            width: 100%;
            span {
                text-transform: uppercase;
                line-height: 1.4;
                font-size: 20px;
                font-weight: 700;
                color: #6c757d;
            }
            p {
                font-size: 12px;
                font-weight: 500;
                color: #adb5bd;
            }
        }
        display: flex !important;
        justify-content: space-between;
        flex-direction: row;
        i {
            display: flex;
            align-items: center;
            font-size: 55px;
            color: rgba(0, 100, 155, 0.5);
            transition: 0.2s ease-in-out;
            position: relative;
            max-width: 55px;
            min-width: 55px;
            width: 55px;
            .material-icons {
                font-family: "Material Icons";
                font-weight: normal;
                font-style: normal;
                font-size: 24px;
                line-height: 1;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                -webkit-font-feature-settings: "liga";
                -webkit-font-smoothing: antialiased;
            }
        }
    }

    .card:hover {
        transform: translateY(2%); /*100%*/
        box-shadow: 2px 2px #adb5bd;
        cursor: pointer;
    }
}

.settings {
    &-groups_list,
    &-users_list {
        max-height: calc(100vh - 345px);
        @include media-breakpoint-down(lg) {
            max-height: calc(100vh - 390px);
        }
        // @media (max-width: 900px) {
        //     max-height: calc(100vh - 310px);
        // }
        // @media (max-width: 645px) {
        //     max-height: calc(100vh - 400px);
        // }
    }
    &-users_list {
        th,
        td {
            &:nth-child(3) {
                width: 200px;
            }
        }
    }
}
