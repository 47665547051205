.breadcrumb {
    display: inline-flex !important;
    &-item {
        a {
            text-decoration: none;
        }
    }
}
nav[aria-label="breadcrumb"] {
    margin-top: 15px;
}
