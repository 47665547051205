$accent: #2978a8;
$primary: #2978a8;
// GRID VARIABLE
$grid-bg: $white;
$grid-header-bg: $gray-100;
$grid-header-text: $dark;
$grid-header-font-size: 13px;
$grid-font-size: 14px;
// $grid-alt-bg: #f9fbfc;
// $grid-border: #eff4f6;
// $grid-header-border: $grid-border;
// $grid-hovered-bg: #eef4f7;
$grid-sorted-bg: transparent;

// $kendo-chip-base-bg: $primary;
$kendo-chip-solid-bg: $gray-700;
$kendo-chip-solid-text: $white;
$kendo-chip-solid-border: $kendo-chip-solid-bg;
$kendo-chip-solid-hover-bg: $kendo-chip-solid-bg;
$kendo-chip-font-size: 0.8rem;
$kendo-chip-line-height: 1;
// $grid-selected-bg: red;
// $pager-text: $primary;

$border-radius: 0;

$kendo-picker-bg: #e4e7eb;
$popup-bg: $white;

$window-title-font-size: 20px;

$kendo-solid-button-shadow: false;
