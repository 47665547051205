footer {
    background-color: #00415e;
    // position: fixed;
    // bottom: 0;
    // right: 0;
    z-index: 999;
    width: 100%;
    white-space: nowrap;
    line-height: 50px;
    color: #fff;
    // padding: 0 15px;
    // float: right;
    // width: calc(100% - $sidebar-width);
    @include transition-sidebar;

    // .sidebar-collapse~& {
    //     width: calc(100% - 50px);
    // }
}

// .side-bar-body-footer {
//     display: flex;

//     flex: 1 0 auto;
// }
// .body-and-footer {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-end;
//     flex: 1;
// }

.footer {
    @media (max-width: 900px) {
        padding: 1rem 0;

        .col {
            text-align: center;
            margin-bottom: 0.5rem;
        }

        height: 95px;
        text-align: center;
        line-height: 2;

        small.ps-3 {
            padding: 0 !important;
        }

        .col-auto {
            flex-grow: 1;
            width: 100%;
            text-align: center;
            white-space: normal;

            .float-end {
                float: initial !important;
            }
        }
    }

    @media (max-width: 645px) {
        height: 125px;
    }

    .float-end>span {
        padding: 15px;

        @include media-breakpoint-down(sm) {
            padding: 15px 5px;
        }

        &:hover {
            color: $white;

            .footer-popup {
                display: block;
            }
        }
    }

    a {
        text-decoration: none;
        color: $white;
        position: relative;

        &:hover {
            color: $white;
        }
    }

    &-popup {
        position: absolute;
        width: 500px;
        bottom: 45px;
        right: 0;
        padding: 15px;
        color: $primary-dark;
        white-space: normal;
        background: rgba(255, 255, 255, 0.9);
        line-height: 1.6;
        border: 1px solid #999;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
        text-align: center;
        display: none;
        font-size: 14px;

        @include media-breakpoint-down(md) {
            position: fixed;
            left: 15px;
            right: 15px;
            width: calc(100% - 30px);
        }

        &-lg {
            width: 700px;
            max-width: 50vw;

            @include media-breakpoint-down(sm) {
                max-height: 350px;
                overflow: auto;
                font-size: 90%;
            }
        }

        a {
            color: $primary-dark;
            padding: 0;
            font-style: italic;
            text-decoration: underline;
            font-weight: bold;

            &:hover {
                color: $primary-dark;
            }
        }

        b {
            font-size: 18px;
            padding-bottom: 5px;
            display: block;

            @include media-breakpoint-down(sm) {
                font-size: 15px;
            }
        }

        u {
            text-decoration: none;
            border-bottom: 1px solid rgba(0, 67, 104, 0.4);
            padding-bottom: 10px;
            margin-bottom: 10px;
            display: inline-block;
        }
    }
}

.footer-anchor,
footer a {
    cursor: pointer;
}