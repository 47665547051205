.pr-create-new {
    .accordion-item {
        margin-bottom: 1rem;
    }
    // .accordion {
    //     &-button {
    //         font-weight: $font-weight-bold;

    //         &:not(.collapsed) {
    //             background-color: transparent;
    //             box-shadow: none;
    //             color: $dark;
    //         }
    //         &:before {
    //             content: "";
    //             height: 2px;
    //             margin: 0 10px;
    //             background-color: #eee;
    //             order: 1;
    //             flex-grow: 1;
    //         }
    //         &:after {
    //             order: 2;
    //         }
    //     }
    //     &-item {
    //         border: 0;
    //     }
    //     &-button,
    //     &-body {
    //         padding-left: 0;
    //         padding-right: 0;
    //     }
    // }
    .k-dialog-buttongroup {
        border-top: 0;
    }
}
.k-combobox.k-loading .k-i-loading {
    margin:8px 10px 0 0;
}
@include media-breakpoint-up(md) {
    .double-width {
        min-width: calc(200% + 60px);
    }
}
.table-line_item-total_section {
    float: right;
    max-width: 336px;
    margin-top: -1px;
    font-size: small;
    td {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 15px;
        vertical-align: middle;
        &:first-child {
            white-space: nowrap;
            font-weight: $font-weight-bold;
        }
        &:nth-child(2) {
            padding-left: 0;
            width: 100%;
        }
    }
    .form-control {
        border: 0;
    }
    .amount-bold{
        font-weight: bold;
        padding-right: 30px;
        
    }
    
}

.pr-approval-view {
    .accordion-item {
        margin-bottom: 20px;
    }
}

.custom-fileupload {
    .k-grid {
        margin-bottom: 1rem;
    }
}

.file-folder {
    & + & {
        margin-top: 5px;
    }
    padding: 5px;
    .k-icon {
        font-size: 24px;
        display: block;
        width: auto;
        opacity: 0.5;
        margin-right: 5px;
    }
    a {
        text-decoration: none;
        font-size: 14px;
    }
    &.file-selected {
        background: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
    }
}
.years-exp
{
    .col-sm-8{
        max-width: 8rem;
    }
}

