// .k-dropdown-button.k-icon.k-i-more-vertical button {
//     height: 30px;
//     margin-left: -18px;
//     background: transparent;
//     border: none;
//     outline: none;
// }
.k-grid {
  &-header {
    font-weight: $font-weight-bold;
  }
  &-toolbar {
    border-bottom: 0 !important;

    &.k-toolbar {
      background-color: #fff;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &-table tbody .k-i-more-vertical button {
    background: none;
    border: none;
    position: absolute;
    height: 100%;
  }
}
.k-grid-pager {
  overflow: auto;
}

.k-pager-info {
    display: flex !important;
  }

.card-body {
  .k-grid {
    border: 0;
  }
}
