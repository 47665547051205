.sidebar {
    padding: 10px 5px;
    width: $sidebar-width;
    background: #f5f5f5;
    font-size: 13px;
    overflow: auto;
    @include transition-sidebar;
    float: left;
    z-index: 2;

    @include media-breakpoint-down(lg) {
        transform: translate(-100%, 0);
        position: absolute;
        left: 0;
        top: 62px;
        height: calc(100vh - 62px);
        width: 100%;

        +.body-and-footer {
            width: 100%;
        }
    }

    &-attach {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;

        &.sidebar-collapse+.body-and-footer {
            max-width: calc(100% - 50px);
        }

        +.body-and-footer {
            @include media-breakpoint-up(lg) {
                max-width: calc(100% - 250px);
            }
        }
    }

    &-item {
        .accordion-button:after {
            display: none;
        }

        &-menu {
            i {
                @include transition-sidebar;

                &:before {
                    content: "menu_open";
                }
            }
        }
    }

    .accordion {
        @include media-breakpoint-down(lg) {
            >.sidebar-item-menu:nth-child(1) {
                display: none;
            }
        }

        &-button {
            padding: 10px;
            background-color: transparent;
            margin-bottom: 5px;
            font-size: 13px;

            &:after {
                width: 0.75rem;
                height: 0.75rem;
                background-size: 0.75rem;
            }

            &:not(.collapsed),
            &:hover{
                box-shadow: none;
                // background-color: rgba($primary, 0.1);
                // border-radius: 4px;
            }
            &.active {
                box-shadow: none;
                background-color: rgba($primary, 0.1);
                border-radius: 4px;
            }

            &:focus {
                box-shadow: none;
                border-color: transparent;
            }
        }

        &-item {
            border: 0;
            background-color: transparent;
        }

        &-header {
            i {
                font-size: 18px;
            }
        }

        &-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &-body {
            padding: 0 0 0 20px;
        }
    }

    i {
        margin-right: 5px;
        opacity: 0.5;
    }

    &.sidebar-collapse {
        width: 50px;

        @include media-breakpoint-down(lg) {

            @at-root .sidebar-logo-toggle,
            & {
                transform: translate(0, 0);
                position: fixed;
                left: 0;
                top: 62px;
                width: 100%;
            }
        }

        @include media-breakpoint-up(lg) {
            i {
                margin-right: 0;
            }

            .sidebar-item-menu i {
                @include transition-sidebar;
                transform: rotate(180deg);
            }

            .accordion {
                &-button {
                    flex-flow: column;
                    justify-content: center;

                    &:after {
                        margin-left: 0;
                        width: 0.5rem;
                        height: 0.5rem;
                        background-size: 0.5rem;
                    }
                }

                &-body {
                    padding: 0;
                }

                &-text {
                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }
            }

            .sidebar {
                &-item {
                    &-text {
                        display: none;
                    }
                }
            }
        }
    }

    .accordion-item:last-of-type .accordion-button.collapsed {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .accordion-item:first-of-type,
    .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.app-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding-bottom: 52px;
    flex: 0 0 auto;
    width: auto;

    @include media-breakpoint-up(lg) {
        min-height: 100vh;
    }

    // @media (max-width: 900px) {
    //     padding-bottom: 100px;
    // }

    // @media (max-width: 645px) {
    //     padding-bottom: 135px;
    // }

    // @include media-breakpoint-down(md) {
    //     padding-bottom: 95px;
    // }
    &,
    .sidebar-collapse+& {
        @include transition-sidebar;
    }
}

.side-bar-body-footer {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
}

.body-and-footer {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    overflow: auto;

    @include media-breakpoint-down(md) {
        padding: 0 1rem;
    }
}