$enable-rounded: false;
$enable-shadows: false;
$primary: #2978a8;
$primary-dark: #00415e;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$black: #000;
// $font-size-base: 0.875rem;
$spacer: 1rem;
$border-radius: 0;

$secondary: $gray-400;
$dark: $gray-800;
$enable-negative-margins: true;
$enable-rounded: true;
$enable-rfs: false;
$variable-prefix: bs-;
$link-decoration: none;
$link-hover-decoration: underline;
$grid-gutter-width: 30px;

$font-family-sans-serif: Roboto, "Helvetica Neue", Arial, "Noto Sans";
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.5;
$font-weight-light: 400;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 500;

$table-cell-padding-y: 8px;
$table-cell-padding-x: 12px;
$table-accent-bg: $white;

$btn-disabled-opacity: 0.45;
$btn-border-radius: 0;
$btn-border-radius-base: 0;
$btn-border-radius-large: 0;
$btn-border-radius-small: 0;
$btn-focus-box-shadow: none;

$input-btn-padding-x: 1rem;
$input-bg: #fff;
$input-color: $gray-700;
$input-border-radius: 0;
$form-label-color: $primary;

// Tab
$nav-tabs-link-hover-border-color: $primary $gray-200 $gray-300;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $primary $gray-300 $white;

// Breadcrumb
$breadcrumb-bg: $gray-100;
$breadcrumb-border-radius: 4px;
$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-font-size: 14px;
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='currentColor' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
// $breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='currentColor' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");

// Navbar
$navbar-padding-y: 0;
$navbar-light-color: $primary-dark;
$navbar-light-hover-color: $navbar-light-color;
$navbar-light-active-color: $navbar-light-color;
$breadcrumb-font-size: 14px;
$navbar-light-toggler-border-color: transparent;
$dropdown-link-hover-bg: #dee2e6;
$nav-link-color: $primary-dark;
$navbar-brand-padding-y: 5px;
$navbar-toggler-focus-width: 0;
$list-group-hover-bg: transparent;
$list-group-active-color: $white;
$list-group-action-hover-color: $white;
$list-group-action-active-color: $white;
$list-group-action-active-bg: transparent;

// $navbar-light-toggler-icon-bg: url("data:image/svg+xml,%3Csvg width='158' height='158' viewBox='0 0 158 158' fill='#{$navbar-light-color}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M79 62.3281C83.4571 62.3281 87.0703 58.7149 87.0703 54.2578C87.0703 49.8007 83.4571 46.1875 79 46.1875C74.5429 46.1875 70.9297 49.8007 70.9297 54.2578C70.9297 58.7149 74.5429 62.3281 79 62.3281Z' fill='#{$white}'/%3E%3Cpath d='M79 87.0703C83.4571 87.0703 87.0703 83.4571 87.0703 79C87.0703 74.5429 83.4571 70.9297 79 70.9297C74.5429 70.9297 70.9297 74.5429 70.9297 79C70.9297 83.4571 74.5429 87.0703 79 87.0703Z' fill='#{$white}'/%3E%3Cpath d='M79 111.813C83.4571 111.813 87.0703 108.199 87.0703 103.742C87.0703 99.2851 83.4571 95.6719 79 95.6719C74.5429 95.6719 70.9297 99.2851 70.9297 103.742C70.9297 108.199 74.5429 111.813 79 111.813Z' fill='#{$white}'/%3E%3Cpath d='M79 154C120.421 154 154 120.421 154 79C154 37.5786 120.421 4 79 4C37.5786 4 4 37.5786 4 79C4 120.421 37.5786 154 79 154Z' stroke='#{$white}' stroke-width='0' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
// Accordion
$accordion-button-bg: #e8f7ff;
$accordion-button-active-bg: $accordion-button-bg;
$accordion-border-width: 0;
// $accordion-button-color: $primary;
// $accordion-button-active-color: $accordion-button-color;
// $accordion-button-padding-y: 0.5rem;
// $accordion-button-padding-x: 0.75rem;
// $accordion-button-focus-box-shadow: none;
$accordion-bg: #f8fdff;

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
